import React, { Component } from 'react'
//import 'Register.css'

class Register extends Component{
    constructor(){
        super()

        this.state = {

        }
    }

    render(){
        return(
            <div>
                
            </div>
        )
    }
}

export default Register

